import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  maritalStatusData: [],
  error: '',
}

export const getMaritalStatusList = createAsyncThunk(
  'maritalStatus/getMaritalStatusList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/maritalStatus`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteMaritalStatus = createAsyncThunk(
  'maritalStatus/deleteMaritalStatus',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/maritalStatus`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' état(s) civil(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' marital status deleted successfully',
      )
      const res = await request(token).get(`/api/tables/maritalStatus`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " état(s) civil(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' marital status could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertMaritalStatus = createAsyncThunk(
  'maritalStatus/insertMaritalStatus',
  async ({ currentMaritalStatus, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/maritalStatus`, {
        data: currentMaritalStatus,
      })
      const message =
        lang === 'Français' ? 'Etat civil ajouté avec succès' : 'Marital status added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/maritalstatus`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'état civil n'a pas pu être ajouté"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The marital status could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateMaritalStatus = createAsyncThunk(
  'maritalStatus/updateMaritalStatus',
  async ({ currentMaritalStatus, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/maritalStatus`, {
        data: currentMaritalStatus,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' état(s) civil(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' marital status updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/maritalStatus`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' état(s) civil(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' marital status updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const maritalStatusSlice = createSlice({
  name: 'maritalStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaritalStatusList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getMaritalStatusList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          maritalStatusData: action.payload,
          error: '',
        }
      })
      .addCase(getMaritalStatusList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertMaritalStatus.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertMaritalStatus.fulfilled, (state, action) => {
        return {
          ...state,
          maritalStatusData: action.payload,
        }
      })
      .addCase(insertMaritalStatus.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateMaritalStatus.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateMaritalStatus.fulfilled, (state, action) => {
        return {
          ...state,
          maritalStatusData: action.payload,
        }
      })
      .addCase(updateMaritalStatus.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteMaritalStatus.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteMaritalStatus.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          maritalStatusData: action.payload,
        }
      })
      .addCase(deleteMaritalStatus.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default maritalStatusSlice.reducer
