import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: false,
  unfoldable: true,
}

const slideSlice = createSlice({
  name: 'slide',
  initialState,
  reducers: {
    changeState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    changeSideBar: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { changeState, changeSideBar } = slideSlice.actions

export default slideSlice.reducer
