import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  institutionData: {},
  minInstitutionData: {},
  error: '',
}

export const getInstitution = createAsyncThunk(
  'institution/getInstitution',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/institution`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getMinInstitutionInfo = createAsyncThunk(
  'institution/getMinInstitutionInfo',
  async () => {
    //const token = thunkAPI.getState().auth.user.token
    const res = await axios.get(`/api/tables/min-institution-info`, {
      baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
        'Content-Type': 'application/json',
        //'Content-Type': 'multipart/form-data',
      },
    })
    const data = await res.data
    return data
  },
)

export const updateInstitution = createAsyncThunk(
  'institution/update',
  async ({ currentEtablissement, lang }, thunkAPI) => {
    try {
      //console.log(thunkAPI.getState())
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/institution`, {
        data: currentEtablissement,
      })
      const message =
        lang === 'Français'
          ? 'Etablissement modifié avec succès'
          : 'Institution updated successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'établissement n'a pas pu être modifié"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The institution could not be updated'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstitution.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getInstitution.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          institutionData: action.payload[0],
          error: '',
        }
      })
      .addCase(getInstitution.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getMinInstitutionInfo.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getMinInstitutionInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          minInstitutionData: action.payload[0],
          error: '',
        }
      })
      .addCase(getMinInstitutionInfo.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default institutionSlice.reducer
