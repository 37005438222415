import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  diplomaData: [],
  error: '',
}

export const getDiplomaList = createAsyncThunk(
  'diploma/getDiplomaList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/diploma`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteDiploma = createAsyncThunk(
  'diploma/deleteDiploma',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/diploma`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' diplôme(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' diploma(s) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/diploma`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " diplôme(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' diploma(s) could not be deleted'
      toast.error(message)
      return thunkAPIrejectWithValue(message)
    }
  },
)

export const insertDiploma = createAsyncThunk(
  'diploma/insertDiploma',
  async ({ currentDiploma, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/diploma`, { data: currentDiploma })
      const message =
        lang === 'Français' ? 'Diplôme ajouté avec succès' : 'Diploma added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/diploma`, {
        params: { annee_universitaire: currentDiploma.ANNEE_UNIVERSITAIRE },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! Le diplôme n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The diploma could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateDiploma = createAsyncThunk(
  'diploma/updateDiploma',
  async ({ currentDiploma, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/diploma`, { data: currentDiploma })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' diplôme(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' diploma(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/diploma`, {
        params: {
          annee_universitaire: selectedYear,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' diplôme(s) mises à jour avec succès'
          : err.response.data.rowUpdated + ' diploma(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const diplomaSlice = createSlice({
  name: 'diploma',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiplomaList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getDiplomaList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          diplomaData: action.payload,
          error: '',
        }
      })
      .addCase(getDiplomaList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertDiploma.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertDiploma.fulfilled, (state, action) => {
        return {
          ...state,
          diplomaData: action.payload,
        }
      })
      .addCase(insertDiploma.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateDiploma.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateDiploma.fulfilled, (state, action) => {
        return {
          ...state,
          diplomaData: action.payload,
        }
      })
      .addCase(updateDiploma.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteDiploma.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteDiploma.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          diplomaData: action.payload,
        }
      })
      .addCase(deleteDiploma.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default diplomaSlice.reducer
