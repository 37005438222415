import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  fieldData: [],
  error: '',
}

export const getFieldList = createAsyncThunk(
  'field/getFieldList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/field`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteField = createAsyncThunk(
  'field/deleteField',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/field`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' filière(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' field(s) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/field`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " filière(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' field(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertField = createAsyncThunk(
  'field/insertField',
  async ({ currentField, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/field`, { data: currentField })
      const message =
        lang === 'Français' ? 'Filière ajoutée avec succès' : 'Field added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/field`, {
        params: { annee_universitaire: currentField.ANNEE_UNIVERSITAIRE },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! La filière n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The field could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateField = createAsyncThunk(
  'field/updateField',
  async ({ currentField, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/field`, { data: currentField })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' filière(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' field(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/field`, {
        params: {
          annee_universitaire: selectedYear,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' filière(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' field(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const fieldSlice = createSlice({
  name: 'field',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFieldList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getFieldList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          fieldData: action.payload,
          error: '',
        }
      })
      .addCase(getFieldList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertField.fulfilled, (state, action) => {
        return {
          ...state,
          fieldData: action.payload,
        }
      })
      .addCase(insertField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateField.fulfilled, (state, action) => {
        return {
          ...state,
          fieldData: action.payload,
        }
      })
      .addCase(updateField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteField.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          fieldData: action.payload,
        }
      })
      .addCase(deleteField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default fieldSlice.reducer
