import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  resultData: [],
  error: '',
}

export const getResultList = createAsyncThunk('result/getResultList', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const res = await request(token).get(`/api/tables/result`)
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const deleteResult = createAsyncThunk(
  'result/deleteResult',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/result`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' Résultat(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' result(ies) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/result`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " résultat(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' result(ies) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertResult = createAsyncThunk(
  'result/insertResult',
  async ({ currentResult, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/result`, { data: currentResult })
      const message =
        lang === 'Français' ? 'Résultat ajouté avec succès' : 'Result added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/result`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! Le résultat n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The result could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateResult = createAsyncThunk(
  'result/updateResult',
  async ({ currentResult, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/result`, { data: currentResult })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' résultat(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' result(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/result`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' résulat(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' result(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResultList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getResultList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          resultData: action.payload,
          error: '',
        }
      })
      .addCase(getResultList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertResult.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertResult.fulfilled, (state, action) => {
        return {
          ...state,
          resultData: action.payload,
        }
      })
      .addCase(insertResult.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateResult.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateResult.fulfilled, (state, action) => {
        return {
          ...state,
          resultData: action.payload,
        }
      })
      .addCase(updateResult.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteResult.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteResult.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          resultData: action.payload,
        }
      })
      .addCase(deleteResult.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default resultSlice.reducer
