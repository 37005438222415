import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isLoadingImport: false,
  isSuccess: false,
  courseData: [],
  courseBySpecialityData: [],
  error: '',
  errorImport: '',
}

export const getCourseList = createAsyncThunk(
  'course/getCourseList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/course`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getCourseBySpeciality = createAsyncThunk(
  'course/getCourseBySpeciality',
  async ({ currentSpeciality, currentTeacher, selectedYear, currentSemester }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/courseBySpeciality`, {
        params: {
          id_spe: currentSpeciality,
          id_ens: currentTeacher,
          annee_universitaire: selectedYear,
          id_sem: currentSemester,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteCourse = createAsyncThunk(
  'course/deleteCourse',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/teaching/course`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' matière(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' course(s) deleted successfully',
      )
      const res = await request(token).get(`/api/teaching/course`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " matière(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' course(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertCourse = createAsyncThunk(
  'course/insertCourse',
  async ({ currentCourse, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teaching/course`, { data: currentCourse })
      const dt = await res1.data
      const message =
        lang === 'Français' ? 'Matière ajoutée avec succès' : 'Course added successfully'
      const res = await request(token).get(`/api/teaching/course`, {
        params: {
          annee_universitaire: currentCourse.ANNEE_UNIVERSITAIRE,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      toast.success(message)
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! La matière n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The course could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateCourse = createAsyncThunk(
  'course/updateCourse',
  async ({ currentCourse, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/course`, { data: currentCourse })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/course`, {
        params: {
          annee_universitaire: selectedYear,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' courses updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const lockUnlockCourse = createAsyncThunk(
  'course/lockUnlockCourse',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/lockUnlockCourse`, {
        data: childrenProps.selected,
        lock: childrenProps.lock,
      })
      const message =
        childrenProps.lock === true
          ? lang === 'Français'
            ? res1.data.rowUpdated + ' matière(s) verrouillée(s) avec succès'
            : res1.data.rowUpdated + ' course(s) locked successfully'
          : lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) déverrouillée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) unlocked successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        childrenProps.lock === true
          ? lang === 'Français'
            ? err.response.data.rowUpdated + ' matière(s) verrouillée(s) à jour avec succès'
            : err.response.data.rowUpdated + ' courses locked successfully'
          : lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) déverrouillée(s) à jour avec succès'
          : err.response.data.rowUpdated + ' courses unlocked successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const bloquerNotes = createAsyncThunk(
  'course/bloquerNotes',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/bloquerNotes`, {
        data: childrenProps.selected,
        lock: childrenProps.lock,
      })
      const message =
        childrenProps.lock === true
          ? lang === 'Français'
            ? res1.data.rowUpdated + ' matière(s) bloquée(s) avec succès'
            : res1.data.rowUpdated + ' course(s) locked successfully'
          : lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) débloquée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) unlocked successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        childrenProps.lock === true
          ? lang === 'Français'
            ? err.response.data.rowUpdated + ' matière(s) bloquée(s) à jour avec succès'
            : err.response.data.rowUpdated + ' courses locked successfully'
          : lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) débloquée(s) à jour avec succès'
          : err.response.data.rowUpdated + ' courses unlocked successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const importCourse = createAsyncThunk(
  'course/importCourse',
  async ({ courseData, fac, lang, importMode, selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/courseImport`, {
        data: courseData,
        id_fac: fac,
        importMode,
        selectedYear,
      })
      const dt = await res1.data
      importMode === 1 || importMode === 2
        ? toast.success(
            lang === 'Français'
              ? dt.rowUpdated + ' matière(s) mise(s) à jour avec succès'
              : dt.rowUpdated + ' course(s) updated successfully',
          )
        : null
      importMode === 1 || importMode === 3
        ? toast.success(
            lang === 'Français'
              ? dt.rowInserted + ` matière(s) insérée(s) avec succès`
              : dt.rowInserted + ' course(s) added successfully',
          )
        : null
      const res = await request(token).get(`/api/teaching/course`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      var message
      importMode === 1 || importMode === 2
        ? (message =
            lang === 'Français'
              ? err.response.data.rowUpdated + ' matière(s) mise(s) à jour avec succès'
              : err.response.data.rowUpdated + ' course(s) updated successfully')
        : null
      importMode === 1 || importMode === 3
        ? (message =
            lang === 'Français'
              ? err.response.data.rowInserted + ` matière(s) insérée(s) avec succès`
              : err.response.data.rowInserted + ' course(s) added successfully')
        : null
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getCourseList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          courseData: action.payload,
          error: '',
        }
      })
      .addCase(getCourseList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateCourse.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          courseData: action.payload,
          error: '',
        }
      })
      .addCase(updateCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertCourse.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertCourse.fulfilled, (state, action) => {
        return {
          courseData: action.payload,
          isSuccess: true,
        }
      })
      .addCase(insertCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteCourse.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          courseData: action.payload,
        }
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(importCourse.pending, (state, action) => {
        return {
          ...state,
          isLoadingImport: true,
        }
      })
      .addCase(importCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: true,
          courseData: action.payload,
          //errorImport: action.payload,
        }
      })
      .addCase(importCourse.rejected, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: false,
          errorImport: action.payload,
        }
      })
      .addCase(getCourseBySpeciality.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getCourseBySpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          courseBySpecialityData: action.payload,
          error: '',
        }
      })
      .addCase(getCourseBySpeciality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(lockUnlockCourse.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(lockUnlockCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          error: '',
        }
      })
      .addCase(lockUnlockCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default courseSlice.reducer
