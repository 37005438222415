import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import request from 'src/utils/request'
import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'

export const updateProfileUser = createAsyncThunk(
  'auth/updateProfileUser',
  async ({ viewProfile, username, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/users/viewProfile`, viewProfile)
      const res = await request(token).get(`/api/users/auth`, {
        params: { user: viewProfile?.id_user, username: username },
      })

      const data = await res.data
      lang === 'Français'
        ? toast.success('Profil modifié avec succès ')
        : toast.success('Profile updated syuccessfully')
      localStorage.setItem('userInfo', JSON.stringify(data))
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

function vertifyToken() {
  try {
    if (!localStorage.getItem('userInfo')) {
      return null
    } else {
      const token = JSON.parse(localStorage.getItem('userInfo')).token
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        localStorage.removeItem('userInfo')
        return null
      } else {
        return JSON.parse(localStorage.getItem('userInfo'))
      }
    }
  } catch (error) {
    console.log(error)
    localStorage.removeItem('userInfo')
    return null
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: vertifyToken(),
    //user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    registerMessage: null,
  },
  reducers: {
    login(state, action) {
      state.user = action.payload
      state.registerMessage = null
    },
    logout(state) {
      state.user = null
    },
    register(state, action) {
      state.registerMessage = action.payload
    },
    setUsername(state, action) {
      state.user.username = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfileUser.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(updateProfileUser.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          user: action.payload,
          error: '',
        }
      })
      .addCase(updateProfileUser.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

const authReducer = authSlice.reducer
const authActions = authSlice.actions

export { authActions, authReducer }
