import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  yearData: [],
  selectedYear: localStorage.getItem('userYear') || null,
  closedYear: false,
  error: '',
}

export const getYearList = createAsyncThunk('year/getYearList', async (_, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token
  const res = await request(token).get(`/api/tables/year`)
  const data = await res.data
  return data
})

export const changeYear = createAsyncThunk(
  'year/changeYear',
  async ({ year, closedYear, lang }, thunkAPI) => {
    try {
      const message =
        lang === 'Français'
          ? `Vous êtes à l'année universitaire ` + year?.toString() + '-' + (year + 1)?.toString()
          : `You are in the academic year ` + year?.toString() + '-' + (year + 1)?.toString()
      toast.success(message)

      return { year, closedYear }
    } catch (err) {
      const message =
        lang === 'Français'
          ? `Erreur de changement d'année universitaire`
          : `Academic year change error`
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const yearSlice = createSlice({
  name: 'year',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getYearList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getYearList.fulfilled, (state, action) => {
        localStorage.getItem('userYear')
          ? null
          : localStorage.setItem('userYear', action.payload[0].ANNEE_UNIVERSITAIRE)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          yearData: action.payload,
          selectedYear: Number(localStorage.getItem('userYear')),
          closedYear:
            action.payload
              .filter(
                (item) => item?.ANNEE_UNIVERSITAIRE === Number(localStorage.getItem('userYear')),
              )
              .map((n) => n.LOCK_ANNEE)[0] === '1',
          error: '',
        }
      })
      .addCase(getYearList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(changeYear.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(changeYear.fulfilled, (state, action) => {
        localStorage.setItem('userYear', action.payload.year)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          selectedYear: Number(localStorage.getItem('userYear')),
          closedYear: action.payload.closedYear,
          error: '',
        }
      })
      .addCase(changeYear.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default yearSlice.reducer
