//import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/userSlice/authSlice'
import facultyReducer from './slices/tableSlice/facultySlice'
import domainReducer from './slices/tableSlice/domainSlice'
import fieldReducer from './slices/tableSlice/fieldSlice'
import diplomaByFieldReducer from './slices/tableSlice/diplomaByFieldSlice'
import diplomaReducer from './slices/tableSlice/diplomaSlice'
import yearReducer from './slices/tableSlice/yearSlice'
import institutionReducer from './slices/tableSlice/institutionSlice'
import modalReducer from './slices/utilSlice/modalSlice'
import slideReducer from './slices/utilSlice/slideSlice'
import languageReducer from './slices/utilSlice/languageSlice'
import wilayaReducer from './slices/tableSlice/wilayaSlice'
import countryReducer from './slices/tableSlice/countrySlice'
import nationalityReducer from './slices/tableSlice/nationalitySlice'
import statusReducer from './slices/tableSlice/statusSlice'
import maritalStatusReducer from './slices/tableSlice/maritalStatusSlice'
import gradeReducer from './slices/tableSlice/gradeSlice'
import statusTeacherReducer from './slices/tableSlice/statusTeacherSlice'
import resultReducer from './slices/tableSlice/resultSlice'
import courseReducer from './slices/teachingSlice/courseSlice'
import teachingUnitReducer from './slices/teachingSlice/teachingUnitSlice'
import specialityReducer from './slices/teachingSlice/specialitySlice'
import departmentReducer from './slices/teachingSlice/departmentSlice'
import teacherReducer from './slices/teachingSlice/teacherSlice'
import studentReducer from './slices/teachingSlice/studentSlice'
import modeleMatriculeReducer from './slices/tableSlice/modeleMatriculeSlice'
import snReducer from './slices/tableSlice/snSlice'
import universityReducer from './slices/tableSlice/universitySlice'
import statsReducer from './slices/tableSlice/statsSlice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    auth: authReducer,
    language: languageReducer,
    institution: institutionReducer,
    year: yearReducer,
    faculty: facultyReducer,
    domain: domainReducer,
    field: fieldReducer,
    diploma: diplomaReducer,
    diplomaByField: diplomaByFieldReducer,
    modal: modalReducer,
    slide: slideReducer,
    wilaya: wilayaReducer,
    country: countryReducer,
    nationality: nationalityReducer,
    status: statusReducer,
    maritalStatus: maritalStatusReducer,
    grade: gradeReducer,
    statusTeacher: statusTeacherReducer,
    result: resultReducer,
    course: courseReducer,
    teachingUnit: teachingUnitReducer,
    speciality: specialityReducer,
    department: departmentReducer,
    teacher: teacherReducer,
    student: studentReducer,
    modeleMatricule: modeleMatriculeReducer,
    sn: snReducer,
    stats: statsReducer,
    university: universityReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
