import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isLoadingImport: false,
  isSuccess: false,
  teachingUnitData: [],
  error: '',
  errorImport: '',
}

export const getTeachingUnitList = createAsyncThunk(
  'teachingUnit/getTeachingUnitList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/teachingUnit`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteTeachingUnit = createAsyncThunk(
  'teachingUnit/deleteTeachingUnit',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/teaching/teachingUnit`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ` unité(s) d'enseignement supprimée(s) avec succès`
          : dt?.rowDeleted + ' teaching unit(s) deleted successfully',
      )
      const res = await request(token).get(`/api/teaching/teachingUnit`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " unité(s) d'enseignement n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' teaching unit(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertTeachingUnit = createAsyncThunk(
  'teachingUnit/insertTeachingUnit',
  async ({ currentTeachingUnit, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teaching/teachingUnit`, {
        data: currentTeachingUnit,
      })
      const dt = await res1.data
      const message =
        lang === 'Français'
          ? `Unité d'enseignement ajoutée avec succès`
          : 'Teaching unit added successfully'
      const res = await request(token).get(`/api/teaching/teachingUnit`, {
        params: {
          annee_universitaire: currentTeachingUnit.ANNEE_UNIVERSITAIRE,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      toast.success(message)
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'unité d'enseignement' n'a pas pu être ajoutée"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The teaching unit could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateTeachingUnit = createAsyncThunk(
  'teachingUnit/updateTeachingUnit',
  async ({ currentTeachingUnit, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/teachingUnit`, {
        data: currentTeachingUnit,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ` unité(s) d'enseignement modifiée(s) avec succès`
          : res1.data.rowUpdated + ' teaching unit(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/teachingUnit`, {
        params: {
          annee_universitaire: selectedYear,
          //id_mat: currentCourse.ID_MAT,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ` unité(s) d'enseignement mise(s) à jour avec succès`
          : err.response.data.rowUpdated + ' teaching unit(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const importTeachingUnit = createAsyncThunk(
  'teachingUnit/importTeachingUnit',
  async ({ teachingUnitData, lang, importMode, selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/teachingUnitImport`, {
        data: teachingUnitData,
        importMode,
        selectedYear,
      })
      const dt = await res1.data
      importMode === 1 || importMode === 2
        ? toast.success(
            lang === 'Français'
              ? dt.rowUpdated + ` unité(s) d'enseignement mise(s) à jour avec succès`
              : dt.rowUpdated + ' teaching unit(s) updated successfully',
          )
        : null
      importMode === 1 || importMode === 3
        ? toast.success(
            lang === 'Français'
              ? dt.rowInserted + ` unité(s) d'enseignement insérée(s) avec succès`
              : dt.rowInserted + ' teaching unit(s) added successfully',
          )
        : null
      const res = await request(token).get(`/api/teaching/teachingUnit`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      var message
      importMode === 1 || importMode === 2
        ? (message =
            lang === 'Français'
              ? err.response.data.rowUpdated + ` unité(s) d'enseignement mise(s) à jour avec succès`
              : err.response.data.rowUpdated + ' teaching unit(s) updated successfully')
        : null
      importMode === 1 || importMode === 3
        ? (message =
            lang === 'Français'
              ? err.response.data.rowInserted + ` unité(s) d'enseignement insérée(s) avec succès`
              : err.response.data.rowInserted + ' teaching unit(s) added successfully')
        : null
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const teachingUnitSlice = createSlice({
  name: 'teachingUnit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeachingUnitList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getTeachingUnitList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          teachingUnitData: action.payload,
          error: '',
        }
      })
      .addCase(getTeachingUnitList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertTeachingUnit.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertTeachingUnit.fulfilled, (state, action) => {
        return {
          teachingUnitData: action.payload,
        }
        //state.isLoading = false
        //state.courseData.push(action.payload[0])
      })
      .addCase(insertTeachingUnit.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateTeachingUnit.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateTeachingUnit.fulfilled, (state, action) => {
        return {
          ...state,
          teachingUnitData: action.payload,
          /*teachingUnitData: state.teachingUnitData.map((item) =>
            item.ID_UE === action.payload[0].ID_UE ? action.payload[0] : item,
          ),*/
        }
      })
      .addCase(updateTeachingUnit.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteTeachingUnit.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteTeachingUnit.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          teachingUnitData: action.payload,
        }
      })
      .addCase(deleteTeachingUnit.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(importTeachingUnit.pending, (state, action) => {
        return {
          ...state,
          isLoadingImport: true,
        }
      })
      .addCase(importTeachingUnit.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: true,
          teachingUnitData: action.payload,
          errorImport: action.payload,
        }
      })
      .addCase(importTeachingUnit.rejected, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: false,
          errorImport: action.payload,
        }
      })
  },
})

export default teachingUnitSlice.reducer
