import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isLoadingImport: false,
  isSuccess: false,
  teacherData: [],
  coursesByTeacherData: [],
  error: '',
  errorImport: '',
}

export const getTeacherList = createAsyncThunk(
  'teacher/getTeacherList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teacher/teacher`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getCoursesByTeacher = createAsyncThunk(
  'teacher/getCoursesByTeacher',
  async ({ teacherID, selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teacher/courseByTeacher`, {
        params: { id_ens: teacherID, annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateTeacher = createAsyncThunk(
  'teacher/updateTeacher',
  async ({ formData, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teacher/teacher`, formData, {
        headers: {
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' enseignant(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' teacher(s) updated successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' enseignant(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' teacher(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateLockDate = createAsyncThunk(
  'teacher/updateLockDate',
  async ({ currentCourseByTeacher, lockDate, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teacher/courseLockDate`, {
        currentCourseByTeacher,
        lockDate,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) updated successfully'
      toast.success(message)

      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' course(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateLockUnlockExam = createAsyncThunk(
  'teacher/updateLockUnlockExam',
  async ({ currentCourseByTeacher, lockCC, lockExam, lockRatt, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teacher/courseLockUnlockExam`, {
        currentCourseByTeacher,
        lockCC,
        lockExam,
        lockRatt,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) updated successfully'
      toast.success(message)

      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' course(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateCourseByTeacher = createAsyncThunk(
  'teacher/updateCourseByTeacher',
  async ({ currentCourseByTeacher, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teacher/courseByTeacher`, currentCourseByTeacher)
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' matière(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' course(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teacher/courseByTeacher`, {
        params: { id_ens: currentCourseByTeacher[0]?.ID_ENS, annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' matière(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' course(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertTeacher = createAsyncThunk(
  'teacher/insertTeacher',
  async ({ currentTeacher, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teacher/teacher`, currentTeacher)
      const message =
        lang === 'Français' ? 'Enseignant ajouté avec succès' : 'Teacher added successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'enseignant' n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The teacher could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertCourseByTeacher = createAsyncThunk(
  'teacher/insertCourseByTeacher',
  async ({ currentCourse, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teacher/courseByTeacher`, {
        data: currentCourse,
      })
      const message =
        lang === 'Français' ? 'Matière ajoutée avec succès' : 'Course added successfully'
      toast.success(message)
      const data = await res1.data

      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! La matière n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The course could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteTeacher = createAsyncThunk(
  'teacher/deleteTeacher',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/teacher/teacher`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' enseignant(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' teacher(s) deleted successfully',
      )
      const res = await request.get(`/api/teacher/teacher`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " enseignant(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' teacher(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteCourseByTeacher = createAsyncThunk(
  'teacher/deleteCourseByTeacher',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/teacher/courseByTeacher`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' matière(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' course(s) deleted successfully',
      )
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " matière(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' course(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const importTeacher = createAsyncThunk(
  'teacher/importTeacher',
  async ({ teacherData, lang, importMode, selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teacher/teacherImport`, {
        data: teacherData,
        importMode,
        selectedYear,
      })
      const dt = await res1.data
      importMode === 1 || importMode === 2
        ? toast.success(
            lang === 'Français'
              ? dt.rowUpdated + ' enseignant(s) mis à jour avec succès'
              : dt.rowUpdated + ' teacher(s) updated successfully',
          )
        : null
      importMode === 1 || importMode === 3
        ? toast.success(
            lang === 'Français'
              ? dt.rowInserted + ` enseignant(s) inséré(s) avec succès`
              : dt.rowInserted + ' teacher(s) added successfully',
          )
        : null

      const data = await res1.data
      return data
    } catch (err) {
      var message
      importMode === 1 || importMode === 2
        ? (message =
            lang === 'Français'
              ? err.response.data.rowUpdated + ' enseignant(s) mis à jour avec succès'
              : err.response.data.rowUpdated + ' teacher(s) updated successfully')
        : null
      importMode === 1 || importMode === 3
        ? (message =
            lang === 'Français'
              ? err.response.data.rowInserted + ` enseignant(s) inséré(s) avec succès`
              : err.response.data.rowInserted + ' teacher(s) added successfully')
        : null
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const teacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getTeacherList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          teacherData: action.payload,
          error: '',
        }
      })
      .addCase(getTeacherList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getCoursesByTeacher.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getCoursesByTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          coursesByTeacherData: action.payload,
          error: '',
        }
      })
      .addCase(getCoursesByTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateCourseByTeacher.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(updateCourseByTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          coursesByTeacherData: action.payload,
          error: '',
        }
      })
      .addCase(updateCourseByTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          //teacherData: action.payload,
        }
      })
      .addCase(updateTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(insertTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          teacherData: action.payload,
        }
      })
      .addCase(insertTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          teacherData: action.payload,
        }
      })
      .addCase(deleteTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(insertCourseByTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertCourseByTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          //coursesByTeacherData: action.payload,
        }
      })
      .addCase(insertCourseByTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteCourseByTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteCourseByTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          //coursesByTeacherData: action.payload,
        }
      })
      .addCase(deleteCourseByTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateLockDate.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateLockDate.fulfilled, (state, action) => {
        return {
          ...state,
          //coursesByTeacherData: action.payload,
        }
      })
      .addCase(updateLockDate.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(importTeacher.pending, (state, action) => {
        return {
          ...state,
          isLoadingImport: true,
        }
      })
      .addCase(importTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: true,
          //teacherData: action.payload,
          //errorImport: action.payload,
        }
      })
      .addCase(importTeacher.rejected, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccess: false,
          errorImport: action.payload,
        }
      })
      .addCase(updateLockUnlockExam.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateLockUnlockExam.fulfilled, (state, action) => {
        return {
          ...state,
          teacherData: action.payload,
        }
      })
      .addCase(updateLockUnlockExam.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default teacherSlice.reducer
