import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'
import axios from 'axios'

const initialState = {
  isLoading: false,
  isSuccess: false,
  countryData: [],
  error: '',
}

export const getCountriesList = createAsyncThunk(
  'country/getCountriesList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/country`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getFreeCountriesList = createAsyncThunk('country/getFreeCountriesList', async () => {
  try {
    const res = await axios.get(`/api/tables/freeCountry`, {
      baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
        'Content-Type': 'application/json',
        //'Content-Type': 'multipart/form-data',
      },
    })
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const deleteCountry = createAsyncThunk(
  'country/deleteCountry',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/country`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' pays(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' country(ies) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/country`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " pays(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' country(ies) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertCountry = createAsyncThunk(
  'country/insertCountry',
  async ({ currentCountry, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/country`, { data: currentCountry })
      const message = lang === 'Français' ? 'Pays ajouté avec succès' : 'Country added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/country`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! Le pays n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The country could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateCountry = createAsyncThunk(
  'country/updateCountry',
  async ({ currentCountry, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/country`, { data: currentCountry })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' pays modifié(s) avec succès'
          : res1.data.rowUpdated + ' country(ies) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/country`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' pays mis à jour avec succès'
          : err.response.data.rowUpdated + ' country(ies) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getCountriesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(getCountriesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateCountry.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(updateCountry.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(updateCountry.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(deleteCountry.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(deleteCountry.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(deleteCountry.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertCountry.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(insertCountry.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(insertCountry.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getFreeCountriesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getFreeCountriesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(getFreeCountriesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default countrySlice.reducer
