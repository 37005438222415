import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  specialityData: [],
  groupData: [],
  error: '',
}

export const getSpecialityList = createAsyncThunk(
  'speciality/getSpecialityList',
  async ({ selectedYear, studentID, free }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/speciality`, {
        params: { annee_universitaire: selectedYear, matricule: studentID, free },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getGroupBySpeciality = createAsyncThunk(
  'speciality/getGroupBySpeciality',
  async ({ currentSpeciality }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/groupBySpeciality`, {
        params: { id_spe: currentSpeciality },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteSpeciality = createAsyncThunk(
  'speciality/delete',
  async ({ childrenProps, multiple, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      if (multiple) {
        const res1 = await request(token).delete(`/api/teaching/speciality`, {
          data: childrenProps.selected,
        })
      } else {
        const res1 = await request(token).delete(`/api/teaching/speciality`, {
          data: [childrenProps.ID_SPE],
        })
      }
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? multiple
            ? dt?.rowDeleted + ' spécialités supprimées avec succès'
            : dt?.rowDeleted + ' spécialité supprimée avec succès'
          : multiple
          ? dt?.rowDeleted + ' specialities removed successfully'
          : dt?.rowDeleted + ' speciality removed successfully',
      )
      const res = await request(token).get(`/api/teaching/speciality`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      if (multiple) {
        const message =
          lang === 'Français'
            ? 'Erreur ! ' +
              err.response.data.errorCount +
              ' sur ' +
              childrenProps.selected.length +
              " spécialités n'ont pas pu être supprimées"
            : 'Error ! ' +
              err.response.data.message.errorNum +
              ' of ' +
              childrenProps.selected.length +
              ' specialities could not be deleted'
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
      } else {
        const message =
          lang === 'Français'
            ? 'Erreur ' +
              err.response.data.message.errorNum +
              " ! La spécialité n'a pas pu être supprimée"
            : 'Error ' +
              err.response.data.message.errorNum +
              ' ! The speciality could not be deleted'
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
      }
    }
  },
)

export const insertSpeciality = createAsyncThunk(
  'speciality/insert',
  async ({ currentSpeciality, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teaching/speciality`, {
        data: currentSpeciality,
      })
      const message =
        lang === 'Français' ? 'Spécialité ajoutée avec succès' : 'Speciality added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/speciality`, {
        params: { annee_universitaire: currentSpeciality.ANNEE_UNIVERSITAIRE },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! La spécialité n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The speciality could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateSpeciality = createAsyncThunk(
  'speciality/update',
  async ({ currentSpeciality, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/speciality`, { data: currentSpeciality })
      const message =
        lang === 'Français' ? 'Spécialité modifiée avec succès' : 'Speciality updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/speciality`, {
        params: {
          annee_universitaire: currentSpeciality.ANNEE_UNIVERSITAIRE,
          id_spe: currentCourse.ID_SPE,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! La spécialité n'a pas pu être modifiée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The speciality could not be updated'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const specialitySlice = createSlice({
  name: 'speciality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialityList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getSpecialityList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          specialityData: action.payload,
          error: '',
        }
      })
      .addCase(getSpecialityList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getGroupBySpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          groupData: action.payload,
          error: '',
        }
      })
      .addCase(getGroupBySpeciality.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
        }
      })
      .addCase(insertSpeciality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          specialityData: action.payload,
        }
      })
      .addCase(insertSpeciality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateSpeciality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          specialityData: action.payload,
        }
      })
      .addCase(updateSpeciality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          specialityData: state.specialityData.map((item) =>
            item.ID_SPE === action.payload[0].ID_SPE ? action.payload[0] : item,
          ),
          error: action.payload,
        }
      })
      .addCase(deleteSpeciality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          specialityData: action.payload,
        }
      })
      .addCase(deleteSpeciality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default specialitySlice.reducer
