import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'
import axios from 'axios'

const initialState = {
  isLoading: false,
  isSuccess: false,
  nationalityData: [],
  error: '',
}

export const getNationalitiesList = createAsyncThunk(
  'nationality/getNationalitiesList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/nationality`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getFreeNationalitiesList = createAsyncThunk(
  'nationality/getFreeNationalitiesList',
  async () => {
    try {
      const res = await axios.get(`/api/tables/freeNationality`, {
        baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
          'Content-Type': 'application/json',
          //'Content-Type': 'multipart/form-data',
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteNationality = createAsyncThunk(
  'nationality/deleteNationality',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/nationality`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' nationalité(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' nationality(ies) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/nationality`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " nationalité(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' nationality(ies) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertNationality = createAsyncThunk(
  'nationality/insertNationality',
  async ({ currentNationality, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/nationality`, {
        data: currentNationality,
      })
      const message =
        lang === 'Français' ? 'Nationalité ajoutée avec succès' : 'Nationality added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/nationality`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! La nationalité n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The nationality could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateNationality = createAsyncThunk(
  'nationality/updateNationality',
  async ({ currentNationality, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/nationality`, { data: currentNationality })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' nationalité(é) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' nationality(ies) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/nationality`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' nationalité(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' nationality(ies) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const nationalitySlice = createSlice({
  name: 'nationality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNationalitiesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getNationalitiesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          nationalityData: action.payload,
          error: '',
        }
      })
      .addCase(getNationalitiesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertNationality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertNationality.fulfilled, (state, action) => {
        return {
          ...state,
          nationalityData: action.payload,
        }
      })
      .addCase(insertNationality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateNationality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateNationality.fulfilled, (state, action) => {
        return {
          ...state,
          nationalityData: action.payload,
        }
      })
      .addCase(updateNationality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteNationality.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteNationality.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          nationalityData: action.payload,
        }
      })
      .addCase(deleteNationality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(getFreeNationalitiesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getFreeNationalitiesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          nationalityData: action.payload,
          error: '',
        }
      })
      .addCase(getFreeNationalitiesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default nationalitySlice.reducer
