import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: 'Français',
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { changeLanguage } = languageSlice.actions

export default languageSlice.reducer
