import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  departmentData: [],
  error: '',
}

export const getDepartmentList = createAsyncThunk(
  'department/getDepartmentList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/teaching/department`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteDepartment = createAsyncThunk(
  'department/deleteDepartment',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/teaching/department`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' département(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' department(s) deleted successfully',
      )
      const res = await request(token).get(`/api/teaching/department`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " département(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' department(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertDepartment = createAsyncThunk(
  'department/insertDepartment',
  async ({ currentDepartment, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/teaching/department`, {
        data: currentDepartment,
      })
      const message =
        lang === 'Français' ? 'Départment ajouté avec succès' : 'Department added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/department`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! Le départment n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The department could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateDepartment = createAsyncThunk(
  'department/updateDepartment',
  async ({ currentDepartment, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/teaching/department`, { data: currentDepartment })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' département(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' department(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/teaching/department`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' département(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' department(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getDepartmentList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          departmentData: action.payload,
          error: '',
        }
      })
      .addCase(getDepartmentList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertDepartment.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertDepartment.fulfilled, (state, action) => {
        return {
          ...state,
          departmentData: action.payload,
        }
      })
      .addCase(insertDepartment.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateDepartment.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        return {
          ...state,
          departmentData: action.payload,
        }
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteDepartment.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          departmentData: action.payload,
        }
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default departmentSlice.reducer
