import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  diplomaByFieldData: [],
  error: '',
}

export const getDiplomaByFieldList = createAsyncThunk(
  'DiplomaByField/getDiplomaByFieldList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/diplomaByField`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteDiplomaByField = createAsyncThunk(
  'DiplomaByField/deleteDiplomaByField',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/diplomaByField`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' diplôme(s) supprimé(s) de la filière avec succès'
          : dt?.rowDeleted + ' diploma(s) deleted  from the field successfully',
      )
      const res = await request(token).get(`/api/tables/diplomaByField`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " diplôme(s) n'ont pas pu être supprimés de la filière"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' diploma(s) could not be deleted from the field'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertDiplomaByField = createAsyncThunk(
  'DiplomaByField/insertDiplomaByField',
  async ({ currentDiplomaByField, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/diplomaByField`, {
        data: currentDiplomaByField,
      })
      const message =
        lang === 'Français'
          ? 'Diplôme ajouté à la filière avec succès'
          : 'Diploma added to the field successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/diplomaByField`, {
        params: { annee_universitaire: currentDiplomaByField.ANNEE_UNIVERSITAIRE },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! Le diplôme n'a pas pu être ajouté à la filière"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The diploma could not be added to the field'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateDiplomaByField = createAsyncThunk(
  'DiplomaByField/updateDiplomaByField',
  async ({ currentDiplomaByField, selectedYear, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/diplomaByField`, {
        data: currentDiplomaByField,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' diplôme(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' diploma(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/diplomaByField`, {
        params: {
          annee_universitaire: selectedYear,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' diplôme(s) mises à jour avec succès'
          : err.response.data.rowUpdated + ' diploma(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const diplomaByFieldSlice = createSlice({
  name: 'DiplomaByField',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiplomaByFieldList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getDiplomaByFieldList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          diplomaByFieldData: action.payload,
          error: '',
        }
      })
      .addCase(getDiplomaByFieldList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertDiplomaByField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertDiplomaByField.fulfilled, (state, action) => {
        return {
          ...state,
          diplomaByFieldData: action.payload,
        }
      })
      .addCase(insertDiplomaByField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateDiplomaByField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateDiplomaByField.fulfilled, (state, action) => {
        return {
          ...state,
          diplomaByFieldData: action.payload,
        }
      })
      .addCase(updateDiplomaByField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteDiplomaByField.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteDiplomaByField.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          diplomaByFieldData: action.payload,
        }
      })
      .addCase(deleteDiplomaByField.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default diplomaByFieldSlice.reducer
