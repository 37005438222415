import axios from 'axios'

/*const request = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
    'Content-Type': 'application/json',
  },
})*/

function request(token) {
  return axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
      'Content-Type': 'application/json',
      //'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
}

export default request
