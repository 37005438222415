import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  snEtablissementData: [],
  error: '',
}

export const getSNEtablissementList = createAsyncThunk(
  'sn/getSNEtablissementList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/sn-etablissement`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const snSlice = createSlice({
  name: 'sn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSNEtablissementList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getSNEtablissementList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          snEtablissementData: action.payload,
          error: '',
        }
      })
      .addCase(getSNEtablissementList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default snSlice.reducer
