import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  gradeData: [],
  error: '',
}

export const getGradeList = createAsyncThunk('grade/getGradeList', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const res = await request(token).get(`/api/tables/grade`)
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const deleteGrade = createAsyncThunk(
  'grade/deleteGrade',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/grade`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' grade supprimé(s) avec succès'
          : dt?.rowDeleted + ' grade deleted successfully',
      )
      const res = await request(token).get(`/api/tables/grade`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " grade(s) n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' grade(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertGrade = createAsyncThunk(
  'grade/insertGrade',
  async ({ currentGrade, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/grade`, currentGrade)
      const message = lang === 'Français' ? 'Grade ajouté avec succès' : 'Grade added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/grade`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! Le grade n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The grade could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateGrade = createAsyncThunk(
  'grade/updateGrade',
  async ({ currentGrade, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/grade`, currentGrade)
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' grade(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' grade(s) updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/grade`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' grade(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' grade(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGradeList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getGradeList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          gradeData: action.payload,
          error: '',
        }
      })
      .addCase(getGradeList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertGrade.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertGrade.fulfilled, (state, action) => {
        return {
          ...state,
          gradeData: action.payload,
        }
      })
      .addCase(insertGrade.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateGrade.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateGrade.fulfilled, (state, action) => {
        return {
          ...state,
          gradeData: action.payload,
        }
      })
      .addCase(updateGrade.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteGrade.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteGrade.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          gradeData: action.payload,
        }
      })
      .addCase(deleteGrade.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default gradeSlice.reducer
