import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  facultyData: [],
  error: '',
}

export const getFacultyList = createAsyncThunk(
  'faculty/getFacultyList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/faculty`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateFaculty = createAsyncThunk(
  'faculty/updateFaculty',
  async ({ currentFaculty, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).put(`/api/tables/faculty`, { data: currentFaculty })
      const message =
        lang === 'Français'
          ? res.data.rowUpdated + ' faculté(s) modifiée(s) avec succès'
          : res.data.rowUpdated + ' faculty(ies) updated successfully'
      toast.success(message)
      /*      const res = await request(token).get(`/api/tables/faculty`, {
        params: {
          annee_universitaire: selectedYear,
          //id_mat: currentCourse.ID_MAT,
        },
      })*/
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' faculté(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' faculty(ies) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertFaculty = createAsyncThunk(
  'faculty/insertFaculty',
  async ({ currentFaculty, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/faculty`, { data: currentFaculty })
      const message =
        lang === 'Français' ? 'Faculté ajoutée avec succès' : 'Faculty added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/faculty`, {
        params: { annee_universitaire: currentFaculty.ANNEE_UNIVERSITAIRE },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! La faculté n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The faculty could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteFaculty = createAsyncThunk(
  'faculty/deleteFaculty',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/faculty`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' faculté(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' faculty(ies) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/faculty`, {
        params: { annee_universitaire: childrenProps.selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " faculté(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' faculty(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const facultySlice = createSlice({
  name: 'faculty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacultyList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getFacultyList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          facultyData: action.payload,
          error: '',
        }
      })
      .addCase(getFacultyList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateFaculty.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(updateFaculty.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
        }
      })
      .addCase(updateFaculty.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(insertFaculty.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertFaculty.fulfilled, (state, action) => {
        return {
          ...state,
          facultyData: action.payload,
        }
      })
      .addCase(insertFaculty.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteFaculty.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteFaculty.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          facultyData: action.payload,
        }
      })
      .addCase(deleteFaculty.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default facultySlice.reducer
