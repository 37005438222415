import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'
import axios from 'axios'

const initialState = {
  isLoading: false,
  isSuccess: false,
  wilayaData: [],
  error: '',
}

export const getWilayaList = createAsyncThunk('wilaya/getWilayaList', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const res = await request(token).get(`/api/tables/wilaya`)
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getFreeWilayaList = createAsyncThunk('wilaya/getFreeWilayaList', async () => {
  try {
    //console.log('free')
    const res = await axios.get(`/api/tables/freeWilaya`, {
      baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
        'Content-Type': 'application/json',
        //'Content-Type': 'multipart/form-data',
      },
    })
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

/*export const getWilayaList = createAsyncThunk('wilaya/getWilayaList', async () => {
  const res = await request.get(`/api/tables/wilaya`)
  const data = await res.data
  return data
})*/

export const deleteWilaya = createAsyncThunk(
  'wilaya/deleteWilaya',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/wilaya`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' wilaya(s) supprimée(s) avec succès'
          : dt?.rowDeleted + ' wilaya(s) deleted successfully',
      )
      const res = await request(token).get(`/api/tables/wilaya`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " wilaya(s) n'ont pas pu être supprimées"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' wilaya(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertWilaya = createAsyncThunk(
  'wilaya/insertWilaya',
  async ({ currentWilaya, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/wilaya`, { data: currentWilaya })
      const message =
        lang === 'Français' ? 'Wilaya ajoutée avec succès' : 'Wilaya added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/wilaya`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! La wilaya n'a pas pu être ajoutée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The wilaya could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateWilaya = createAsyncThunk(
  'wilaya/updateWilaya',
  async ({ currentWilaya, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/wilaya`, { data: currentWilaya })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' wilaya(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' wilaya updated successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' wilaya(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated + ' wilaya(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const wilayaSlice = createSlice({
  name: 'wilaya',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWilayaList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getWilayaList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          wilayaData: action.payload,
          error: '',
        }
      })
      .addCase(getWilayaList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertWilaya.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertWilaya.fulfilled, (state, action) => {
        return {
          ...state,
          wilayaData: action.payload,
        }
      })
      .addCase(insertWilaya.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })

      .addCase(deleteWilaya.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteWilaya.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          wilayaData: action.payload,
        }
      })
      .addCase(deleteWilaya.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(getFreeWilayaList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getFreeWilayaList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          wilayaData: action.payload,
          error: '',
        }
      })
      .addCase(getFreeWilayaList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default wilayaSlice.reducer
