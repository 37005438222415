import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  statusTeacherData: [],
  error: '',
}

export const getStatusTeacherList = createAsyncThunk(
  'statusTeacher/getStatusTeacherList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/tables/statusTeacher`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const deleteStatusTeacher = createAsyncThunk(
  'statusTeacher/deleteStatusTeacher',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/tables/statusTeacher`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' status supprimé(s) avec succès'
          : dt?.rowDeleted + ' status deleted successfully',
      )
      const res = await request(token).get(`/api/tables/statusTeacher`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " status n'ont pas pu être supprimés"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' status could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertStatusTeacher = createAsyncThunk(
  'statusTeacher/insertStatusTeacher',
  async ({ currentStatusTeacher, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/tables/statusTeacher`, {
        data: currentStatusTeacher,
      })
      const message =
        lang === 'Français' ? 'Status ajouté avec succès' : 'Status added successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/statusTeacher`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' + err.response.data.message.errorNum + " ! Le status n'a pas pu être ajouté"
          : 'Error ' + err.response.data.message.errorNum + ' ! The status could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateStatusTeacher = createAsyncThunk(
  'statusTeacher/updateStatusTeacher',
  async ({ currentStatusTeacher, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/tables/statusTeacher`, {
        data: currentStatusTeacher,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' status modifié(s) avec succès'
          : res1.data.rowUpdated + ' status updated successfully'
      toast.success(message)
      const res = await request(token).get(`/api/tables/statusTeacher`)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' status mis à jour avec succès'
          : err.response.data.rowUpdated + ' status updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const statusTeacherSlice = createSlice({
  name: 'statusTeacher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusTeacherList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getStatusTeacherList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          statusTeacherData: action.payload,
          error: '',
        }
      })
      .addCase(getStatusTeacherList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(insertStatusTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(insertStatusTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          statusTeacherData: action.payload,
        }
      })
      .addCase(insertStatusTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(updateStatusTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(updateStatusTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          statusTeacherData: action.payload,
        }
      })
      .addCase(updateStatusTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
      .addCase(deleteStatusTeacher.pending, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(deleteStatusTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          statusTeacherData: action.payload,
        }
      })
      .addCase(deleteStatusTeacher.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.payload,
        }
      })
  },
})

export default statusTeacherSlice.reducer
