import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  title: '',
  message: '',
  componentName: null,
  childrenProps: {},
  size: '',
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true
      state.componentName = action.payload.componentName
      state.title = action.payload.title
      state.type = action.payload.type
      state.message = action.payload.message
      state.childrenProps = action.payload.childrenProps
      state.multiple = action.payload.multiple
      state.size = action.payload.size
    },
    closeModal: (state, action) => {
      state.isOpen = false
      state.componentName = null
      state.title = ''
      state.type = ''
      state.message = ''
      state.childrenProps = {}
      state.multiple = false
      state.size = ''
    },
  },
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
